body {
  background-color: #e0f5f4;
}

.mainContainer { 
  border:5px solid #08b0a0;
}

.titleBlock {
  background-color: #08b0a0;
  color: white;
  font-size: larger;
  font-weight: bold;
  margin:20px;
  text-align: center;
  padding:10px;
}

.App-logo {
  max-height: 80px;
  padding:20px;
}

.innerContainer{
  border:5px solid #08b0a0;
  margin-bottom:20px;
}

.subTitleBlock {
  background-color: #08b0a0;
  color: white;
  font-size: large;
  font-weight: bold;
  padding:10px;
}

.subTitleNoteBlock {
  padding-top: 10px;
}

.warningMessage{
  color:red;
}

.PlaceOrder{
  margin-right: 10px;
  margin-bottom: 10px;
}

.TotalPrice{
  font-size: larger;
}

